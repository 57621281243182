// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-boxes-js": () => import("./../src/pages/boxes.js" /* webpackChunkName: "component---src-pages-boxes-js" */),
  "component---src-pages-coasters-js": () => import("./../src/pages/coasters.js" /* webpackChunkName: "component---src-pages-coasters-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-jewelry-js": () => import("./../src/pages/jewelry.js" /* webpackChunkName: "component---src-pages-jewelry-js" */),
  "component---src-pages-laserwords-js": () => import("./../src/pages/laserwords.js" /* webpackChunkName: "component---src-pages-laserwords-js" */),
  "component---src-pages-misc-js": () => import("./../src/pages/misc.js" /* webpackChunkName: "component---src-pages-misc-js" */),
  "component---src-pages-papercraft-js": () => import("./../src/pages/papercraft.js" /* webpackChunkName: "component---src-pages-papercraft-js" */),
  "component---src-pages-pillows-js": () => import("./../src/pages/pillows.js" /* webpackChunkName: "component---src-pages-pillows-js" */),
  "component---src-pages-switchplates-js": () => import("./../src/pages/switchplates.js" /* webpackChunkName: "component---src-pages-switchplates-js" */),
  "component---src-pages-terrariums-js": () => import("./../src/pages/terrariums.js" /* webpackChunkName: "component---src-pages-terrariums-js" */)
}

